<!-- 
	This is the DataTables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>

	<div>

		<!-- Searchable Datatable card -->
		<a-card :bordered="false" class="header-solid mb-24 px-25" :bodyStyle="{padding: 0, paddingTop: '16px'}">
			<a-row class="mx-25 pt-10">
				<a-col :span="12">
					<h5 class="font-semibold">Add Student</h5>
				</a-col>
				<a-col :span="12" class="text-right">
					<a-button type="primary" href="/student-management/students" class="px-20 mb-10">
						<a-icon type="arrow-left" theme="outlined" class="text-white mr-5" /> Back
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-0 pt-0"/>
				</a-col>
			</a-row>
			
			<div class="mx-25 pb-25">
				<a-row :gutter="24">
					<a-col :span="24" :md="24">
						<a-form-model
							:model="newItem"
							:form="form"
							class="login-form mb-0 pb-0"
							layout="vertical"
							@submit.prevent="handleSubmit">

							<a-row :gutter="24">
								<a-col :span="8" :lg="8" :md="8" :sm="8">
									<a-form-item 
										class="mt-20 mb-10 pb-0" 
										label="First Name *"
										:colon="false">
											<a-input 
												v-decorator="['firstname', { rules: [{ required: true, message: 'Please input your note!' }] }]"
												v-model="newItem.firstName"/>
									</a-form-item>
								</a-col>

								<a-col :span="8" :lg="8" :md="8" :sm="8">
									<a-form-item 
										class="mt-20 mb-10 pb-0" 
										label="Middle Name"
										:colon="false">
											<a-input 
												v-decorator="['middleName', { rules: [{ required: true, message: 'Please input your note!' }] }]"
												v-model="newItem.middleName"/>
									</a-form-item>
								</a-col>

								<a-col :span="8" :lg="8" :md="8" :sm="8">
									<a-form-item 
										class="mt-20 mb-10 pb-0" 
										label="Last Name *"
										:colon="false">
											<a-input 
												v-decorator="['lastName', { rules: [{ required: true, message: 'Please input your note!' }] }]"
												v-model="newItem.lastName"/>
									</a-form-item>
								</a-col>

							</a-row>


							<a-row :gutter="24">
								<a-col :span="8" :lg="8" :md="8" :sm="8">
									<a-form-item 
										class="mt-20 mb-10 pb-0" 
										label="Gender *"
										placeholder="Select Gender"
										:colon="false">
										<a-select v-model="newItem.gender" placeholder="Select Gender">
											<a-select-option v-for="option in genders"
												:value="option.uuid"
												:key="option.name">
												{{ option.name }}
											</a-select-option>
										</a-select>
									</a-form-item>
								</a-col>

								<a-col :span="8" :lg="8" :md="8" :sm="8">
									<a-form-item 
										class="mt-20 mb-10 pb-0" 
										label="Date of Birth"
										:colon="false">
											<a-date-picker
												v-model="newItem.dateOfBirth2" 
												format="DD MMM YYYY"
												style="width: 100%"
												placeholder="" />
									</a-form-item>
								</a-col>

								<a-col :span="8" :lg="8" :md="8" :sm="8">
									<a-form-item 
										class="mt-20 mb-10 pb-0" 
										label="Religion"
										:colon="false">
										<a-select v-model="newItem.religion" placeholder="Select Religion">
											<a-select-option v-for="option in religions"
												:value="option"
												:key="option">
												{{ option }}
											</a-select-option>
										</a-select>
									</a-form-item>
								</a-col>

							</a-row>


							<a-row :gutter="24">

								<a-col :span="8" :lg="8" :md="8" :sm="8">
									<a-form-item 
										class="mt-20 mb-10 pb-0" 
										label="Date of Joining"
										:colon="false">
											<a-date-picker
												v-model="newItem.dateOfJoining2" 
												format="DD MMM YYYY"
												style="width: 100%"
												placeholder="" />
									</a-form-item>
								</a-col>

								<a-col :span="8" :lg="8" :md="8" :sm="8">
									<a-form-item 
										class="mt-20 mb-10 pb-0" 
										label="Nationality"
										:colon="false">
											<a-select 
												show-search
												v-model="newItem.nationality" 
												placeholder="Select Country"
												:options="countries.map(option => ({ label: option.label, value: option.label }))" />
									</a-form-item>
								</a-col>

								<a-col :span="8" :lg="8" :md="8" :sm="8">
									<a-form-item 
										class="mt-20 mb-10 pb-0" 
										label="Picture of Student (Passport Size)"
										:colon="false">
											<a-input 
												v-decorator="['studentPicture', { rules: [{ required: true, message: 'Please input your note!' }] }]"
												v-model="newItem.studentPicture"/>
									</a-form-item>
								</a-col>

							</a-row>

							<a-row :gutter="24">

								<a-col :span="8" :lg="8" :md="8" :sm="8">
									<a-form-item 
										class="mt-20 mb-10 pb-0" 
										label="Address"
										:colon="false">
											<a-textarea :rows="4" placeholder="" v-model="newItem.address"/>
									</a-form-item>
								</a-col>

							</a-row>

							<a-row :gutter="24">

								<a-col :span="24" :lg="24" :md="24" :sm="24">
									<a-form-item class="mt-20"> 
										<a-button type="primary" :loading="newItem.loading" html-type="submit" class="">
											{{ newItem.isCreate ? 'Save' : 'Update' }}
										</a-button>
									</a-form-item>
								</a-col>

							</a-row>

						</a-form-model>
					</a-col>
					
				</a-row>
			</div>
		</a-card>

	</div>

</template>

<script>
	import { Countries } from '../Resources/Countries'

	const countries = Countries;

	export default {
		components: {
			// Countries
		},
		data() {
			return {

				form: this.$form.createForm(this, { name: 'newItemForm' }),

				genders: [
					{ uuid: "Male", name: "Male" },
					{ uuid: "Female", name: "Female" },
				],
				countries,
				religions: ["Christian", "Muslim", 'Buddhist', "Hindu", "Others", "Non-Religious"],
				newItem: {
					uuid: null,
					firstName: null,
					middleName: null,
					lastName: null,
					gender: null,
					address: null,
					dateOfBirth: null,
					dateOfBirth2: null,
					religion: null,
					nationality: null,
					dateOfJoining: null,
					dateOfJoining2: null,
					studentPicture: null,
					isCreate: true,
					loading: false,
					showModal: false,
				},


			}
		},
		created() {
		},
		methods: {

			async handleSubmit() {

				if(this.newItem.isCreate) {

					this.newItem.loading = true;
		
					let url = `${this.$BACKEND_URL}/students`
					
					this.newItem.uuid = undefined;
					this.newItem.dateOfBirth = this.newItem.dateOfBirth2 != null && this.newItem.dateOfBirth2.$d != null ? this.$Moment(this.newItem.dateOfBirth2.$d).format("YYYY-MM-DD") : this.newItem.dateOfBirth2; 
					this.newItem.dateOfJoining = this.newItem.dateOfJoining2 != null && this.newItem.dateOfJoining2.$d != null ? this.$Moment(this.newItem.dateOfJoining2.$d).format("YYYY-MM-DD") : this.newItem.dateOfJoining2; 

					this.$AXIOS.post(url, this.newItem).then(response => {

						if (response.status >= 200 && response.status < 210) {

							this.form.resetFields()

							this.newItem.uuid = null;
							this.newItem.firstName = null;
							this.newItem.middleName = null;
							this.newItem.lastName = null;
							this.newItem.gender = null;
							this.newItem.address = null;
							this.newItem.dateOfBirth = null;
							this.newItem.dateOfBirth2 = null;
							this.newItem.religion = null;
							this.newItem.nationality = null;
							this.newItem.dateOfJoining = null;
							this.newItem.dateOfJoining2 = null;
							this.newItem.studentPicture = null;
							this.newItem.isCreate = true;
							this.newItem.loading = false;
							this.newItem.showModal = false;

							let message = response.data.message
							let type = "success"

							this.notify(type, message)

						}

					}).catch(error => {
					
						this.newItem.loading = false;
			
						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						let type = "danger"

						this.notify(type, message)
			
					});

				}else{

					this.newItem.loading = true;
		
					let url = `${this.$BACKEND_URL}/students/${this.newItem.uuid}`

					this.newItem.dateOfBirth = this.newItem.dateOfBirth2 != null && this.newItem.dateOfBirth2.$d != null ? this.$Moment(this.newItem.dateOfBirth2.$d).format("YYYY-MM-DD") : this.newItem.dateOfBirth2; 
					this.newItem.dateOfJoining = this.newItem.dateOfJoining2 != null && this.newItem.dateOfJoining2.$d != null ? this.$Moment(this.newItem.dateOfJoining2.$d).format("YYYY-MM-DD") : this.newItem.dateOfJoining2; 

					this.$AXIOS.patch(url, this.newItem).then(response => {

						if (response.status >= 200 && response.status < 210) {

							this.form.resetFields()

							this.newItem.uuid = null;
							this.newItem.firstName = null;
							this.newItem.middleName = null;
							this.newItem.lastName = null;
							this.newItem.gender = null;
							this.newItem.address = null;
							this.newItem.dateOfBirth = null;
							this.newItem.dateOfBirth2 = null;
							this.newItem.religion = null;
							this.newItem.nationality = null;
							this.newItem.dateOfJoining = null;
							this.newItem.dateOfJoining2 = null;
							this.newItem.studentPicture = null;
							this.newItem.isCreate = true;
							this.newItem.loading = false;
							this.newItem.showModal = false;

							let message = response.data.message
							let type = "success"

							this.notify(type, message)

						}
		
					}).catch(error => {
					
						this.newItem.loading = false;
			
						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						let type = "danger"

						this.notify(type, message)
					});
				}
				
			},


			notify(type, message) {
				this.$notification.open({
					class: 'ant-notification-' + type,
					message: message,
					duration: 3.0,
					style: {fontSize: "10px !important"}
				});
			},

		},

		
	}

</script>

<style lang="scss">

	#txtBtn, #txtBtn:hover {
		border: none;
	}
</style>